// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LogLevel } from '@wellro/utils';

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBdr3MDwCY1Ltz1er0SeJBCSCjPRPUvIOk',
    authDomain: 'wellro-wellness-staging.firebaseapp.com',
    projectId: 'wellro-wellness-staging',
    storageBucket: 'wellro-wellness-staging.appspot.com',
    messagingSenderId: '125729254720',
    appId: '1:125729254720:web:7b77c5a8f0ad087f70dea5',
    measurementId: 'G-48D4M408EY',
  },
  sentry: {
    dsn:
      'https://a5b0288e22064f479e678171f3b079cf@o4504682974740480.ingest.sentry.io/4504693552250880',
    env: 'staging',
  },
  tenantId: 'system-user-mq38v',
  logLevel: LogLevel.DEBUG,
  hostUrl: 'https://staging-admin.wellro.life',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
